import { loginout } from '@/api';
import { useStorage } from '@vueuse/core';
import { getLoginUri } from '@/utils/envv';

const hiworkLogoutUrl = getLoginUri();
const visitorSign = useStorage('visitor-sign', '');
const source = useStorage('source', '');
const udesk = useStorage('udesk', '');
const companyId = useStorage('company_id', '');
const hisnotice = useStorage('hisnotice', '');
export const hLogOut = () => {
  if (visitorSign.value) {
    visitorSign.value = null;
    window.location.href = `${hiworkLogoutUrl}?loginout=1`;
  } else {
    loginout().then((res) => {
      if (res.data.code == 22000) {
        localStorage.clear();
        companyId.value = null;
        hisnotice.value = null;
        udesk.value = null;
        if (source.value == 'hiwork') {
          source.value = null;
          window.location.href = `${hiworkLogoutUrl}?reback=${encodeURIComponent(
            window.location.href,
          )}&loginout=1`;
        } else {
          let ttlurl =
            hiworkLogoutUrl +
            '?hiworkurl=' +
            window.location.protocol +
            '//' +
            window.location.host +
            '&reback=' +
            encodeURIComponent(window.location.href) +
            '&loginout=1';
          window.location.href = ttlurl;
        }
      }
    });
  }
};
