import service from '@/utils/axios';
import { getApiUri } from '@/utils/envv';
import qs from 'qs';
const api = getApiUri() + '/api';

export const accountList = (params: object) => {
  return service.get(`${api}/account/index`, { params });
};

export const accountRoleList = () => {
  return service.get(`${api}/account/roleList`);
};

export const accountInviteUser = (params: object) => {
  return service.post(`${api}/account/inviteUser`, qs.stringify(params));
};

// 转让
export const accountAssignMember = () => {
  return service.get(`${api}/account/assignMember`);
};

export const accountAssign = (params: object) => {
  return service.post(`${api}/account/assign`, qs.stringify(params));
};

export const accountDelete = (params: object) => {
  return service.post(`${api}/account/delete`, qs.stringify(params));
};
// 取消邀请
export const inviteRecordcancel = (params: object) => {
  return service.get(
    `${api}/account/inviteRecord/cancel?` + qs.stringify(params),
  );
};
// 获取邀请记录详情
export const inviteRecord = (params: object) => {
  return service.get(`${api}/account/inviteRecord?` + qs.stringify(params));
};
