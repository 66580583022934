import { createApp } from 'vue';
import store from './store/index';
import router from './router';
import { useAuth } from './utils/auth';
import 'virtual:uno.css';
import App from './App.vue';
import i18n from './languages/index';
import './assets/iconFont/iconfont.css';
import './assets/iconFont/iconfont2.css';
import './assets/iconFont/iconfont3.css';
import 'element-plus/theme-chalk/dark/css-vars.css';
import './style.css';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { throttleClick } from '@/directives/throttle';
import CountryFlag from 'vue-country-flag-next';
import * as Sentry from '@sentry/vue';
import pck from '../package.json';
import { getEnv } from '@/utils/envv';
useAuth(router).then(() => {
  const app = createApp(App);
  app.component('VueCountryFlag', CountryFlag);
  app.directive('throttle', throttleClick);
  app.use(router);
  app.use(store);
  app.use(i18n);
  app.use(VueDOMPurifyHTML);
  app.use(ElementPlus);
  if (getEnv() === 'prod') {
    Sentry.init({
      app,
      dsn: 'https://f0720766d0272b73538f8238f31360e3@sentry.hiworkglobal.com/17',
      release: pck.version,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  app.mount('#app');
});
