<template>
  <div v-if="activeCode" class="business">
    <el-select
      :model-value="activeCode"
      class="w-100%"
      @change="hcompanychange"
    >
      <el-option
        v-for="item in companylist"
        :key="item.customerCode"
        :label="item.customerFullName"
        :value="item.customerCode"
      />
      <template #prefix>
        <img src="@/assets/images/company.png" class="w-16px h-16px" alt="" />
      </template>
    </el-select>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { companychange } from '@/api';
import { useGlobalStore } from '@/store/module/global';
import { useRouter } from 'vue-router';
import { useStorage } from '@vueuse/core';

const router = useRouter();
const companyId = useStorage('company_id', '');

const globalStore = useGlobalStore();
const activeCode = computed(() => globalStore.userinfo.company_id);
const companylist = computed(() => globalStore.companys);

const hcompanychange = (company_id: any) => {
  const params = {
    target_company_id: company_id,
    flush_token: globalStore.flushToken,
  };
  companychange(params).then((res) => {
    if (res.data.code === 22000) {
      companyId.value = company_id;
      router.push('/home').then(() => {
        location.reload();
      });
    }
  });
};
</script>
<style lang="less" scoped>
.business {
  :deep(.el-dropdown-link) {
    display: flex;
    align-items: center;
    .text {
      flex: 1;
      line-height: 20px;
      font-size: 12px;
      color: var(--hiwork-global-color);
    }
  }
  :deep(.el-input__suffix) {
    .el-icon {
      font-size: 12px !important;
      color: var(--hiwork-global-color10) !important;
    }
  }
}
</style>
