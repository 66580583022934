import {
  gettoken,
  // getUdesk,
  getuserinfo,
  // getCompanyConfig,
  // companyServiceEntrance,
} from '@/api';
import { useStorage } from '@vueuse/core';
import { Router } from 'vue-router';
import { useGlobalStore } from '@/store/module/global';
import { useCookies } from '@vueuse/integrations/useCookies';
import { getLoginUri } from './envv';

export const useAuth = async (router: Router) => {
  const cookies = useCookies();
  cookies.remove('Token', {
    path: '/',
    domain: 'hiwork.com',
  });
  function getQueryString(name: string): string | null {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
    const queryString = window.location.search.substr(1);
    const match = queryString.match(reg);
    if (match) {
      return decodeURIComponent(match[2]);
    }
    return null;
  }
  const visitorSign = useStorage('visitor-sign', '');
  const source = useStorage('source', '');
  // const udesk = useStorage('udesk', '');
  const companyId = useStorage('company_id', '');
  const hiworkLogoutUrl = getLoginUri();

  if (getQueryString('token')) {
    if (getQueryString('token') == 'NO_BIND') {
      visitorSign.value = 'PAYMENT_VISITOR_SIGN';
      window.location.href =
        window.location.protocol + '//' + window.location.host + '/nocompany';
    } else {
      visitorSign.value = null;
      source.value = getQueryString('from');
      // if (!localStorage.getItem('ttl')) {
      await gettoken({ token: getQueryString('token') }).then((res: any) => {
        if (res.data.code == 22000) {
          localStorage.setItem('ttl', res.data.result.ttl);
          if (getQueryString('reback')) {
            window.location.href = getQueryString('reback') as string;
          } else {
            if (getQueryString('label')) {
              window.location.href =
                window.location.protocol +
                '//' +
                window.location.host +
                '/home' +
                '?label=' +
                getQueryString('label');
            } else {
              window.location.href =
                window.location.protocol +
                '//' +
                window.location.host +
                '/home';
            }
          }
          // });
        } else if (res.data.code == 22001) {
          window.location.href = `${hiworkLogoutUrl}?loginout=1`;
        }
      });
      // } else {
      //   if (getQueryString('reback')) {
      //     window.location.href = getQueryString('reback') as string;
      //   } else {
      //     window.location.href =
      //       window.location.protocol + '//' + window.location.host + '/home';
      //   }
      // }
    }
  } else {
    router.beforeEach((to, _from, next) => {
      if (to.path == '/') {
        next('/home');
      }
      // let ttl = '0';
      if (localStorage.getItem('ttl')) {
        getuserinfo({}).then((res: any) => {
          if (res.data.code == 22000) {
            localStorage.setItem('userinfo', JSON.stringify(res.data.result));
            const globalStore = useGlobalStore();
            globalStore.setUserinfo(res.data.result);
            companyId.value = res.data.result.company_id;
            if (globalStore.companys.length == 0 || !globalStore.flushToken)
              globalStore.setCompanys();
            if (
              res.data.result.manage_roles.includes('service:payment:global') ||
              res.data.result.manage_roles.includes('default:master')
            ) {
              next();
            } else {
              if (to.path === '/noAuth' || to.path === '/nocompany') {
                next();
              } else {
                next({ path: '/noAuth' });
              }
            }
          } else {
          }
        });
      } else {
        if (visitorSign.value) {
          next();
        } else {
          const ttlurl = `${hiworkLogoutUrl}?hiworkurl=${encodeURIComponent(
            window.location.href,
          )}&reback=${encodeURIComponent(window.location.href)}`;
          window.location.href = ttlurl;
        }
      }
    });
  }
  return null;
};
