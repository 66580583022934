export default {
  layout: {
    loadingmsg: '正在导出，请耐心等待',
  },
  common: {
    all: '全部',
    empty: '当前内容为空',
    newVersion: '平台有新版本发布，请刷新页面继续操作',
    refreshrightnow: '立即刷新',
    authFailedTitle: '提示',
    authFailedTip: '身份验证已失效，请返回上一页重新获取验证。',
    reauth: '重新认证',
    cancel: '取消',
  },
  home: {
    welcome: '欢迎使用',
    quickEntry: '快捷入口',
    pendingPaym: '待处理付款',
    pending: '待处理',
    improveEntity: '完善我方发票开票信息',
    toImprove: '去完善',
    collectionAccount: '收款账户',
    headerText: '欢迎来到 HiWork 全球用工平台，快速创建你的信息吧',
    headerBtnText: '了解更多',
    entryText1: '创建结算单',
    entryText2: 'account to be credited',
    entryText3: '导入人员',
    entryText4: 'collection record',
    lookmore: '查看更多',
    toPay: '去付款',
    copy: '复制',
    switch: '切换',
    subject: '合作主体',
    subject1: '主体',
    bankAccountName: '账户名称',
    bankAccountNo: '账号',
    accountType: '账户类型',
    currency: '收款币种',
    bankName: '开户银行',
    bankAddress: '开户行地址',
    bankCode: '银行代码',
    branchCode: '支行代码',
    swiftCode: 'Swift Code',
    peoplenumber: '{number}人',
    currency1: '币种',
    pwdBoxmsg:
      'HiWork同时支持密码和手机验证码两种登录方式，当前账号尚未设置登录密码，是否现在设置？',
    pwdBoxTitle: '提示',
    pwdBoxConfirm: '设置密码',
    pwdBoxCancel: '暂不设置',
  },
  tabs: {
    refresh: '刷新',
    maximize: '最大化',
    closeCurrent: '关闭当前',
    closeLeft: '关闭左侧',
    closeRight: '关闭右侧',
    closeOther: '关闭其它',
    closeAll: '关闭所有',
  },
  header: {
    componentSize: '组件大小',
    language: '国际化',
    theme: '全局主题',
    layoutConfig: '布局设置',
    primary: 'primary',
    darkMode: '暗黑模式',
    greyMode: '灰色模式',
    weakMode: '色弱模式',
    fullScreen: '全屏',
    exitFullScreen: '退出全屏',
    personalSetting: '个人设置',
    changePassword: '修改密码',
    logout: '退出登录',
    helpCenter: '帮助中心',
    toHomeHiWork: '去往国内版',
  },
  menu: {
    home: '首页',
    personnelmanagement: '人员管理',
    settlementmanagement: '结算单管理',
    batchsettlement: '结算批次',
    detailsettlement: '结算明细',
    setup: '设置',
    accountManagement: '账号管理',
    information: '企业信息',
    personalInfo: '个人信息',
    invoicesManagement: '发票管理',
  },
  routeTitle: {
    home: '首页',
    personnelmanagement: '人员管理',
    personnelDetail: '人员详情',
    createContract: '创建合同',
    addPeople: '添加人员',
    batchImport: '批量导入',
    settlementmanagement: '结算单管理',
    batchsettlement: '结算批次',
    createBatch: '创建结算批次',
    detailsettlement: '结算明细',
    orderDetails: '结算单详情',
    accountManagement: '账号管理',
    information: '企业信息',
    personalInfo: '个人信息',
    editEmail: '修改邮箱',
    editPassword: '修改密码',
    setPassword: '设置密码',
    editPhone: '修改手机号',
    invoicesManagement: '发票管理',
  },
  personnelList: {
    name: '人员偏好姓名',
    country: '国家',
    mailbox: '邮箱',
    status: '状态',
    createtime: '创建时间',
    personnelId: '人员编号',
    country1: '国家/地区',
    taxCountryCode: '税收国家/地区',
    settlementCurrency: '结算币种',
    personnelStatus: '人员状态',
    progress: '进度',
    operation: '操作',
    look: '查看',
    urge: '催办',
    delete: '删除',
    addPeople: '添加人员',
    batchImport: '批量导入',
    export: '导出',
    delSuccess: '删除成功',
    urgeSuccessTip1: '催办成功，已给用户邮箱[{email}]发送催办邮件',
    urgeSuccessTip2: '催办成功，运营人员会尽快处理',
  },
  personnelDetail: {
    nickname: '个人偏好姓名',
    email: '邮箱',
    status: '人员状态',
    personnelId: '用户编号',
    createtime: '创建时间',
    country1: '国家/地区',
    settlementCurrency: '结算币种',
    personnelStatus: '人员状态',
    progress: '进度',
    operation: '操作',
    look: '查看',
    urge: '催办',
    delete: '删除',
    edit: '编辑',
    moreOperation: '更多操作',
    cancelSign: '取消签署',
    noContract: '该人员无有效合同',
    createContract: '创建合同',
    accountInfo: '账户信息',
    downloadPDF: '下载PDF',
    lookhistorycontract: '查看历史合同',
    cancelContractmsg: '是否确认取消签署',
    cancelContractTitle: '取消签署',
    opsuccess: '操作成功',
    contractCode: '合同编号',
    contractName: '合同名称',
    contractStatus: '合同状态',
    name1: '姓名',
    jobTitle: '职位名称',
    jobDescription: '职位描述',
    agencyName: '签约主体',
    createTime: '创建时间',
    signTime: '签署时间',
    taxCountry: '税收国家/地区',
    activeTime: '激活时间',
    userCode: '用户编号',
    name: '法定名字',
    enName: '英文法定名字',
    accountStatus: '账户状态',
    authentication: '身份认证',
    pay: '收款账户',
    registerTime: '注册时间',
    emailtootipmsg: '邮箱不可修改，如需修改请删除人员重新创建。',
    countrytootipmsg: '税收国家需要与该人员收款账户所属国家保持一致。',
    cancel: '取消',
    confirm: '确认',
    editpersonnel: '编辑人员信息',
    editpersonnelmsg:
      '其他个人信息需要用户登录HiWork 全球用工平台个人端进行更新。',
    historycontract: '历史合同列表',
    contractInfo: '合同信息',
    operationRecord: '操作记录',
    download: '下载文件',
  },
  createContract: {
    contractInfo: '合同信息',
    contractHistory: '查看历史合同',
    moreOperation: '更多操作',
    cancelSign: '取消签署',
    noContract: '该人员无有效合同',
    createContract: '创建合同',
    accountInfo: '账户信息',
    contractName: '合同名称',
    contractType: '合同类型',
    contractStatus: '合同状态',
    contractNumber: '合同编号',
    contractAmount: '合同金额',
    contractCurrency: '合同币种',
    contractDeadline: '合同截止日期',
    contractDescription: '合同描述',
    contractAttachment: '合同附件',
    contractSign: '合同签署',
    contractCancel: '合同取消',
    contractSubmit: '合同提交',
    preview: '预览',
    email: '邮箱',
    template: '合同模板',
    jobTitle: '职位名称',
    jobDescription: '职位描述',
    input: '请输入',
    select: '请选择',
    contractNameerrmsg: '请输入合同名称',
    emilerrmsg: '请输入邮箱',
    templateerrmsg: '请选择合同模板',
    jobTitleerrmsg: '请输入职位名称',
    jobDescriptionerrmsg: '请输入职位描述',
    createSuccess: '创建成功',
  },
  addPeople: {
    nickname: '人员偏好姓名',
    contractInfo: '合同信息',
    contractHistory: '查看历史合同',
    moreOperation: '更多操作',
    cancelSign: '取消签署',
    noContract: '该人员无有效合同',
    addPeople: '创建并邀请签署',
    accountInfo: '账户信息',
    contractName: '合同名称',
    contractType: '合同类型',
    contractStatus: '合同状态',
    contractNumber: '合同编号',
    contractAmount: '合同金额',
    contractCurrency: '合同币种',
    contractDeadline: '合同截止日期',
    contractDescription: '合同描述',
    contractAttachment: '合同附件',
    contractSign: '合同签署',
    contractCancel: '合同取消',
    contractSubmit: '合同提交',
    preview: '预览',
    taxCountryCode: '税收国家/地区',
    mailbox: '邮箱',
    settlementCurrency: '结算币种',
    templateName: '合同名称',
    templateCode: '合同模版',
    jobTitle: '职位名称',
    jobDescription: '职位描述',
    contractNameerrmsg: '请输入合同名称',
    emailerrmsg: '请输入邮箱',
    taxCountryCodeerrmsg: '请选择税收国家/地区',
    settlementCurrencyerrmsg: '请选择结算币种',
    templateCodeerrmsg: '请选择合同模版',
    jobTitleerrmsg: '请输入职位名称',
    jobDescriptionerrmsg: '请输入职位描述',
    nickNameerrmsg: '请输入员工偏好姓名',
    addSuccess: '添加成功',
  },
  batchImport: {
    uploadTable: '上传表格',
    verificationInformation: '校验信息',
    invitationtosign: '邀请签署',
    finish: '完成',
    nextStep: '下一步',
    return: '返回',
    export: '导出明细',
    invitation: '立即邀请',
    viewpeoplelist: '查看人员列表',
    contractTemplate: '合同模版',
    personList: '人员名单',
    preview: '预览',
    downloadtemp: '模版下载',
    selectText: '请选择合同模版',
    uploadError: '请上传文件',
    uploadtext1: '点击或将文件拖到此处进行上传',
    uploadtext2: '支持{types}格式，大小不超过{size}M',
    name: '姓名',
    mailbox: '邮箱',
    country: '税收国家/地区',
    currency: '币种',
    contractName: '合同名称',
    jobTitle: '职位名称',
    jobDescription: '职位描述',
    checkResult: '校验结果',
    operation: '操作',
    mailContent: '邮件内容',
    invitationSent: '邀请已发送',
    detelDialogTitle: '删除',
    detelDialogContent: '确定要删除该人员数据吗？删除后无法恢复。',
    detelDialogTitleOk: '确定',
    detelDialogTitleNo: '取消',
    delSuccess: '删除成功',
    uploadExceed: '只能上传一个文件',
    countryCode: '国家/地区代码',
  },
  settlementBatch: {
    export: '导出',
    createSettlementBatch: '创建结算批次',
    title: '结算单标题',
    subject: '合作主体',
    createTime: '创建时间',
    paymentCurrency: '付款币种',
    code: '结算单号',
    servicePeopleNumber: '人数',
    totalAmount: '总金额',
    status: '状态',
    operation: '操作',
    look: '查看',
    statusTooltip:
      '正在等待HiWork 全球用工平台确认入账，预计需要1个工作日，确认后将自动打款到个人钱包余额。',
  },
  createBatch: {
    uploadTable: '上传表格',
    verificationInformation: '校验信息',
    amount: '核算金额',
    title: '结算单标题',
    subject: '合作主体',
    currency: '付款币种',
    batch: '结算批次',
    uploadtext1: '点击或将文件拖到此处进行上传',
    uploadtext2: '支持{types}格式，大小不超过{size}M',
    uploadtext3: '文件大小不能超过20M, 请重新上传',
    downloadtemp: '模版下载',
    nextStep: '下一步',
    return: '返回',
    export: '导出明细',
    submit: '提交',
    step3contractContent: `正在邀请您通过 HiWork签约，请查看合同并签署。您需要通过在线链接填写个人信息，预祝工作顺利。`,
    titlerule: '结算单标题不能为空',
    subjectrule: '合作主体不能为空',
    currencyrule: '付款币种不能为空',
    batchrule: '文件不能为空',
    nickname: '偏好姓名',
    mailbox: '邮箱',
    personnelID: '人员编号',
    servicecontent: '服务内容',
    settlementCurrency: '结算币种',
    settlementamount: '结算金额',
    checkResult: '校验结果',
    operation: '操作',
    checkResultPass: '校验通过',
    del: '删除',
    delSuccess: '删除成功',
    detelDialogTitle: '删除',
    detelDialogContent: '确定要删除该结算单吗？删除后无法恢复。',
    detelDialogTitleOk: '确定',
    detelDialogTitleNo: '取消',
    submitSuccess: '提交成功',
    employeeCode: '人员编号',
    settlementAmount: '结算金额',
    serviceFeeAmount: '管理服务费',
    email: '邮箱',
    count: 'Contractor人数',
    totalSettlementAmount: '总结算金额({currency})',
    totalServiceFeeAmount: '管理服务费({currency})',
    handlingServiceFeeAmount: '收款手续费({currency})',
    vatAmount: '增值税及附加({currency})',
    serviceFeeRate: '服务费率',
    totalAmount: '总计({currency})',
    totalAmountTip: '总计=（总结算金额+管理服务费+收款手续费）*（1+服务费率）',
    paymentCurrencymsg: '您付款给我们的币种',
    uploadExceed: '只能上传一个文件',
    invoicesInfo: '开票项目信息',
    invoicesInfoTip:
      '系统将按照“描述+时间范围”的方式列明您的Contractor结算费用，为您自动开具Invoice',
    description: '描述',
    descriptionrule: '描述不能为空',
    descriptionTip:
      '描述中不得出现“结算”、“支付”、“工资”“salary”“payment”“income”等敏感文字。',
    timerule: '时间范围不能为空',
    timeRanges: '时间范围',
    paymentEntityInfo: '付款方信息',
    companyName: '企业名称',
    address: '地址',
    pleaseComplete: '请完善您的Invoice主体信息',
    confirmInvoice: '确认Invoice信息',
    confirmInvoiceBtn: '确认并继续',
    cancelInvoiceBtn: '返回',
  },
  orderDetails: {
    orderNumber: '结算单号',
    moreActions: '更多操作',
    downloadForm: '下载请款单',
    lookdetail: '查看明细',
    amountdetail: '金额明细',
    downloadInvoice: '下载Invoice',
    confirmpayment: '确认付款',
    awaitpayment: '等待付款',
    cooperativesubjects: '合作主体',
    statementTitle: '结算单标题',
    totalamount: '总金额',
    settlementpersonnelnumber: '结算人数',
    paymentcurrency: '付款币种',
    settlementamount: '结算金额',
    managementservicefee: '管理服务费',
    collectionservicefee: '收款手续费',
    vattax: '增值税及附加',
    totalamount2:
      '(Contractor结算费用+管理服务费+收款手续费)*服务费率({rate}%)',
    createTime: '创建时间',
    name: '姓名',
    mailbox: '邮箱',
    personnelID: '人员编号',
    employeepreferencename: '人员偏好姓名',
    country: '国家/地区',
    servicecontent: '服务内容',
    settlementamountpayable: '结算金额 (应付)',
    status: '状态',
    finishtime: '完成时间',
    handlerecord: '处理记录',
    input: '请输入',
    select: '请选择',
    email: '邮箱',
    opsuccess: '操作成功',
    confirmpaymentmsg: '请确认您已线下打款 {amount} {currency} 到我司账户',
    cancelSettlement: '取消结算',
    cancelSettlementmsg: '确定要取消结算吗？取消后无法恢复。',
    payPrompt: '付款说明：',
    toPayPromptMsg1:
      '1.由于汇率波动，请在5个工作日内完成付款，否则结算单将取消，需要重新提交结算单核算金额。',
    toPayPromptMsg2:
      ' 2.如果您使用SWIFT转账，请选择“OUR”模式，以保证我们全额收到您的款项。',
    countDownFormat: 'DD天HH时mm分ss秒',
    countDownBefore: '付款倒计时',
    reSubmit: '重新提交',
    payAccount: '付款账户',
    copyall: '复制全部',
    title1: 'Contractor结算金额合计',
  },
  detailsettlement: {
    name: '姓名',
    country: '国家',
    mailbox: '邮箱',
    createtime: '创建时间',
    country1: '国家/地区',
    personnelStatus: '人员状态',
    progress: '进度',
    operation: '操作',
    look: '查看',
    urge: '催办',
    delete: '删除',
    addPeople: '添加人员',
    batchImport: '批量导入',
    export: '导出',
    settlementCode: '结算单号',
    settlementDetailCode: '结算单明细号',
    userCode: '用户编号',
    employeeCode: '人员编号',
    nickname: '人员偏好姓名',
    email: '邮箱',
    subjectName: '合作主体',
    settlementCurrency: '结算币种',
    settlementAmount: '结算金额',
    serviceFeeAmount: '管理服务费',
    status: '状态',
    createTime: '创建时间',
    paymentCompletionTime: '完成时间',
    settlementCode1: '结算批次ID',
    nickname1: '偏好姓名',
    paymentCurrency: '付款币种',
    input: '请输入',
    select: '请选择',
    all: '全部',
    withdrawStatus: '提现状态',
    withdrawStatusTip1: '等待个人手动提现，请个人登录个人端（{uri}）进行提现',
    withdrawStatusTip2: '具体进度需人员登录个人端（{uri}）查看。',
    withdrawStatusTip3: '结算款项已提现到个人的收款账号。',
  },
  nocompany: {
    text: '您的商户暂未开通相关产品',
    text2: '请联系您的客户经理或者拨打电话400-686-1151',
  },
  noAuth: {
    text: '您没有操作权限',
    text2: '请联系您的客户经理开通相关权限',
  },
  placeholder: {
    input: '请输入',
    select: '请选择',
  },
  setup: {
    accountList: {
      name: '用户姓名',
      email: '邮箱',
      phone: '手机号',
      role: '角色',
      account: '账号状态',
      operation: '操作',
      add: '添加成员账号',
      delete: '删除',
      detelDialogTitle: '删除',
      detelDialogContent:
        '确认删除该账号吗？删除后该账号将无法查看您的企业或进行企业的相关操作',
      delSuccess: '删除成功',
      transference: '转让管理员',
      roleText: '管',
      canceltitle: '确认要取消本次邀请吗？',
      cancelContent:
        '取消邀请后，用户邀请链接将失效，如需邀请，请重新添加成员账号。',
      cancelSuccess: '取消邀请成功',
      cancel: '取消邀请',
      again: '重新邀请',
    },
    accountForm: {
      title: '添加账号',
      return: '返回',
      phone: '手机号',
      phonePlacholder: '请输入受邀人手机号',
      email: '邀请邮箱',
      emailPlacholder: '请输入受邀人邮箱',
      role: '邀请角色',
      tip: '添加完成后，将向此邮箱发送邀请加入邮件通知',
      btn: '完成',
      phoneInput: '手机号不能为空',
      emitInput: '邮箱不能为空',
      check: '至少选择一个邀请角色',
      roleTip:
        '受邀账号的默认角色为“普通管理员”，将拥有HiWork平台上除了企业信息、账号管理外的所有权限。',
    },
    transference: {
      title: '企业管理员转让',
      return: '返回',
      executeTitle: '您正在进行企业管理员角色的转让',
      executeContent:
        '转让后，您的账户将会降低成为该企业的普通账号，请从下方账号列表中选择转让对象',
      executeNo: '无可转让的企业成员',
      btn: '确认转让',
      tip: {
        front: '转让的用户不在此列表中？请先添',
        centre: '添加为企业成员',
        after: '再转让',
      },
      success: {
        title: '企业管理员角色转让成功',
        centre: '{v} 已经成为新的企业管理员',
        btn: '完成',
      },
    },
    informationForm: {
      title: '企业信息',
      tip: '如需修改您的企业信息，请联系客服处理。',
      tel: '联系电话: 400-668-5151',
      statusStr: '认证状态',
      business: '营业执照名称',
      company: '公司常用名称',
      address: '详细地址',
      certificate: '证照类型',
      institution: '组织机构代码',
    },
  },
  setUpPersonalInfo: {
    companyName: '企业名',
    role: '角色',
    operation: '操作',
    exitCompany: '退出企业',
    exitCompanyTip:
      '退出企业后，您将不能操作企业相关功能或接收企业信息。如需再次加入，请联系您的企业管理员。',
    exitCompanysuccess: '退出企业成功',
    exitCompanyConfirmTitle: '确认退出 {customerName} 吗？',
    exitCompanyConfirmBtn: '确认退出',
    switchCompanyTip:
      '本次操作需要先切换至 {customerName}, 是否切换当前使用的企业？',
    switchCompanyConfirmTitle: '切换企业',
    switchCompanyConfirmBtn: '确认切换',
    adminExitConfirmBtn: '确认转让',
    adminexittip: '当前无可转让的企业成员 {link}',
    adminexittip2: '添加为企业成员',
    adminexittip3:
      '您是企业管理员，如需退出企业，请先转让管理员身份给其他成员。转让的用户不在此列表中？请先 {link} 再转让。',
    adminexitsuccess: '转让管理员成功',
    noselectAdmin: '没有选择要转让的人员',
    edit: '修改',
    set: '设置',
    basic: '基本信息',
    myCompany: '我加入的企业',
    name: '姓名',
    phone: '手机号码',
    email: '业务邮箱',
    password: '登录密码',
    editname: '修改姓名',
    cancel: '取消',
    confirm: '确认',
    editNameSuccess: '姓名修改成功',
    currentCompany: '当前企业',
    role2: '管',
    editPassword: {
      oldPassword: '旧密码',
      newPassword: '新密码',
      confirmPassword: '确认密码',
      cancel: '取消',
      confirm: '确认',
      editPasswordSuccess: '密码修改成功',
      verify: '验证身份',
      verifyTip: '您正在设置密码，我们需要验证您的身份',
      verifyTip2: '请完成手机验证码验证',
      setNewPassword: '设置密码',
      finish: '完成',
      relogin: '返回',
      nextStep: '下一步',
      confirmChange: '确认修改',
      updateTip: '设置密码',
      setpassword: '设置密码',
      success: '密码修改成功',
      finishTip: '密码设置成功',
    },
    editEmail: {
      email: '邮箱',
      cancel: '取消',
      confirm: '确认',
      editEmailSuccess: '邮箱修改成功',
      verify: '验证身份',
      verifyTip: '您正在设置邮箱，我们需要验证您的身份',
      verifyTip2: '请完成手机验证码验证',
      setNewEmail: '设置新邮箱',
      finish: '完成',
      nextStep: '下一步',
      updateTip: '您好，您正在进行邮箱设置',
      success: '邮箱修改成功',
      finishTip: '密码重置成功，请使用新密码重新登录',
    },
    editPhone: {
      phone: '手机号',
      newPhone: '新手机号',
      cancel: '取消',
      confirm: '确认',
      editPhoneSuccess: '手机号修改成功',
      verify: '验证身份',
      verifyTip: '您正在设置手机号，我们需要验证您的身份',
      verifyTip2: '请选择下列任意方式验证',
      updateTip: '您正在修改登录手机号，修改成功可使用新号码登录',
      finishTip: '新手机号设置成功',
      finishTip2: '为了您的账户安全，请使用新手机号重新登录',
      setNewPhone: '设置新手机号',
      finish: '完成',
      nextStep: '下一步',
      relogin: '重新登录',
    },
    verifyCode: '手机验证码',
    code: '验证码',
    sendCode: '获取验证码',
    reSend: '重新发送',
    sending: '发送中...',
    currentPhone: '当前手机号',
    getVoiceCode: '获取语音验证码',
    codesendSuccess: '验证码发送成功',
    codesending: '已发送({count})s',
    isSet: '已设置',
    notSet: '未设置',
  },
  invoicesManagement: {
    invoicesRecords: '发票记录',
    invoicesInfomation: '发票信息',
    mainbody: '主体信息',
    tip: '仅展示您与HiWork非中国大陆主体合作的Invoice主体信息，若需确认您与HiWork中国大陆主体开票信息，可联系您的客户支持，或拨打电话：400-668-5151。',
    ourSubject: '我方主体信息',
    cooperationSubject: '合作主体信息',
    invoiceNum: '发票编号',
    invoiceType: '发票类型',
    completed: '已完善',
    modify: '修改',
    tocompleted: '待完善',
    tomodify: '去完善',
    ourTip:
      '请补全您的Invoice信息，信息完善后，系统将在您每次发起结算后，自动为您开具Invoice',
    improveEntity: '完善我的主体信息',
    editEntity: '修改我的主体信息',
    editEntityTip1:
      '修改以下信息，不会影响您的历史发票，未来新开具的发票将按照新地址开具',
    editEntityTip2: '您所选主体支持自动开具Invoice，请先完善您的主体信息',
    editEntityTip3: '您的开票主体与企业认证主体一致，不可修改。',
    entityName: '主体名称',
    addresst: '地址信息',
    country: '国家/地区',
    province: '州省',
    city: '城市',
    address: '详细地址',
    validcountry: '国家/地区不能为空',
    validprovince: '州省不能为空',
    validcity: '城市不能为空',
    validaddress: '详细地址不能为空',
    editSuccess: '开票地址已更新成功',
    invoiceNo: '发票编号',
    dateofissuance: '开具日期',
    projectDescription: '项目描述',
    settlementnumber: '结算单号',
    invoicestatus: '开票状态',
    amount: '开票金额',
    currency: '币种',
    invoiceDate: '时间范围',
    totalSettlementAmount: '结算金额',
    status: '状态',
    operation: '操作',
    totalServiceFeeAmount: '管理服务费',
    handlingServiceFeeAmount: '收款手续费',
    download: '下载',
    recordsTip:
      '展示您与HiWork非中国大陆主体合作的Invoice列表，若需开具您与HiWork中国大陆主体合作的发票，可联系您的客户支持，或拨打电话：{phone}。',
  },
  rules: {
    // 统一正则错误提示
    phone: '手机号格式错误',
    email: '邮箱格式错误',
    password: '密码需为8位以上数字+大小写字母组合',
    code: '验证码格式有误',
    confirmpassword: '两次输入密码不一致',
    length: '开票项目信息描述需在2-200字符',
    validateEmojiTip: '请输出有效的描述，不得出现emoji等特殊字符',
  },
};
