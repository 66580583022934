import service from '@/utils/axios';
import qs from 'qs';
import { getApiUri } from '@/utils/envv';
const api = getApiUri() + '/api';

// 结算明细核算
export const settlementamountcalculate = (params: any) => {
  return service.post(`${api}/settlement/amount/calculate`, params);
};
// 结算单提交
export const settlementconfirm = (params: any) => {
  return service.post(`${api}/settlement/confirm`, params);
};
// 结算明细列表
export const settlementdetaillist = (params: any) => {
  return service.post(`${api}/settlement/detail/list`, params);
};
// 结算单详情
export const settlementdetails = (params: any) => {
  return service.get(`${api}/settlement/details?${qs.stringify(params)}`);
};
// 结算明细校验
export const settlementdetailscheck = (params: any) => {
  return service.post(`${api}/settlement/details/check`, params);
};
// 结算明细校验结果删除
export const settlementdetailscheckdelete = (params: any) => {
  return service.post(`${api}/settlement/details/check/delete`, params);
};
// 结算明细校验列表
export const settlementdetailschecklist = (params: any) => {
  return service.post(`${api}/settlement/details/check/list`, params);
};
// 结算单列表
export const settlementlist = (params: any) => {
  return service.post(`${api}/settlement/list`, params);
};
// 取消结算
export const settlementcancel = (params: any) => {
  return service.post(`${api}/settlement/cancel`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
// 确认付款
export const settlementconfirmpayment = (params: any) => {
  return service.post(`${api}/settlement/confirm/payment`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
// 结算枚举
export const settlementenums = () => {
  return service.get(`${api}/settlement/enums`);
};
// 结算枚举
export const settlementpaymentfileinfo = (params: any) => {
  return service.get(
    `${api}/settlement/payment/file/info?${qs.stringify(params)}`,
  );
};
// 导出明细
export const settlementdetailexport = (params: any) => {
  return service.post(`${api}/settlement/detail/list/export`, params);
};
// 导出批次
export const settlementlistexport = (params: any) => {
  return service.post(`${api}/settlement/list/export`, params);
};
// 结算单操作记录
export const settlementoperationrecordlist = (params: any) => {
  return service.get(
    `${api}/settlement/operation/record/list?${qs.stringify(params)}`,
  );
};
// 结算单重新提交
export const settlementreSubmit = (params: any) => {
  return service.post(`${api}/settlement/reSubmit`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
