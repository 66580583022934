<template>
  <el-main>
    <div class="el-main-content-ccc">
      <router-view v-slot="{ Component, route }">
        <keep-alive :include="keepAliveName">
          <component
            :is="Component"
            v-if="isRouterShow"
            :key="route.fullPath + globalStore.language"
          />
        </keep-alive>
      </router-view>
    </div>
  </el-main>
</template>
<script setup lang="ts">
import { useGlobalStore } from '@/store/module/global';
import { useKeepAliveStore } from '@/store/module/keepAlive';
import { computed, provide, ref, watch } from 'vue';
const globalStore = useGlobalStore();
const keepAliveStore = useKeepAliveStore();
const keepAliveName = computed(() => keepAliveStore.keepAliveName);
// 提供刷新当前页面的方法
const isRouterShow = ref(true);
const refreshCurrentPage = (val: boolean) => (isRouterShow.value = val);
provide('refresh', refreshCurrentPage);
// 监听当前页面是否最大化，动态添加 class
watch(
  () => globalStore.maximize,
  () => {
    const app = document.getElementById('app') as HTMLElement;
    if (globalStore.maximize) app.classList.add('main-maximize');
    else app.classList.remove('main-maximize');
  },
  { immediate: true },
);
defineExpose({
  refreshCurrentPage,
});
</script>
<style lang="less">
.el-main {
  // position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 28px !important;
  padding-top: 0 !important;
  background-color: var(--hiwork-global-bg-color);
}

.el-main-content-ccc {
  width: 100%;
  // min-width: 1020px;
  height: 100%;
  // background-color: var(--el-bg-color);
}
</style>
