import service from '@/utils/axios';
import { getApiUri } from '@/utils/envv';
const api = getApiUri() + '/api';

//  添加人员并邀请签署
export const locationcountry = (params: object) => {
  return service.post(`${api}/location/country`, params);
};

//  获取nc参数
export const ncparams = () => {
  return service.get(`${api}/nc/params`);
};
