import service from '@/utils/axios';
import qs from 'qs';
import { getApiUri } from '@/utils/envv';
const api = getApiUri() + '/api';

//  获取公司合作主体
export const companysubjectlist = (params: any) => {
  return service.get(`${api}/company/subject/list?${qs.stringify(params)}`);
};
//  收款账户列表
export const companycollectionaccountlist = () => {
  return service.get(`${api}/company/collection/account/list`);
};
//  收款账户列表(新)
export const companycollectionaccount = () => {
  return service.get(`${api}/company/collection/account`);
};
