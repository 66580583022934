import axios from 'axios';
import qs from 'qs';
import service from '@/utils/axios';
import { getApiUri } from '@/utils/envv';
const api = getApiUri() + '/api';

export const gettoken = (params: any) => {
  return axios.post(`${api}/sso/getTtl`, params);
};
export const getUdesk = (params: any) => {
  return axios.get(`${api}/udesk/get_params`, {
    headers: { token: localStorage.getItem('ttl') },
    params,
  });
};
// 退出登陆
export const loginout = () => {
  return service.post(`${api}/sso/logout`);
};
// 获取用户信息
export const getuserinfo = (params: any) => {
  return service.get(`${api}/user/info`, {
    headers: { token: localStorage.getItem('ttl'), 'customer-code': '' },
    showloading: true,
    show404: true,
    params,
  });
};
// 获取商户配置
export const getCompanyConfig = (params: any) => {
  return service.post(`${api}/company/config`, qs.stringify(params));
};
//  能否终止结算单
export const companyServiceEntrance = (params: any) => {
  return service.get(`${api}/company/service/entrance`, { params });
};

//  获取用户绑定的公司列表
export const companybindlist = () => {
  return service.get(`${api}/user/company/bind/list`);
};

//公司切换
export const companychange = (params: any) => {
  return service.post(`${api}/user/company/change`, params);
};
