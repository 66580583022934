import service from '@/utils/axios';
import { getApiUri } from '@/utils/envv';
const api = getApiUri() + '/api';

//  查询合同模板
export const contracttemplate = (params: any) => {
  return service.post(`${api}/contract/template`, params);
};
//  创建合同
export const contractcreateContract = (params: any) => {
  return service.post(`${api}/contract/createContract`, params);
};
//  预览合同模板
export const contractpreview = (params: any) => {
  return service.post(`${api}/contract/preview`, params);
};
//  取消签署合同
export const contractcancel = (params: any) => {
  return service.post(`${api}/contract/cancel`, params);
};
//  合同列表
export const contractlist = (params: any) => {
  return service.post(`${api}/contract/list`, params);
};
//  合同列表
export const contractdetail = (params: any) => {
  return service.post(`${api}/contract/detail`, params);
};
//  下载pdf
export const contractdownloadpdf = (params: any) => {
  return service.post(`${api}/contract/download/pdf`, params);
};
