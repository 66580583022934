const apiuris = {
  test: '//test-app-api.hiworkglobal.com',
  demos: '//app-hiworkglobal-api.hiwork.com',
  qa: '//qa-app-api.hiworkglobal.com',
  prod: '//app-api.hiworkglobal.com',
};
export const getEnv = () => {
  const host = window.location.host;
  if (host.includes('localhost')) return 'test';
  if (host.includes('test')) return 'test';
  if (host.includes('qa')) return 'qa';
  if (host === 'app-hiworkglobal.hiwork.com') return 'demos';
  return 'prod';
};

export const getApiUri = () => {
  const env = getEnv();
  // return ''; // 开代理
  return apiuris[env];
};

const loginuris = {
  test: '//test-home.hiworkglobal.com/login',
  demos: '//home-global.hiwork.com/login',
  qa: '//qa-home.hiworkglobal.com/login',
  prod: '//home.hiworkglobal.com/login',
};

export const getLoginUri = () => {
  const env = getEnv();
  return loginuris[env];
};
const iuris = {
  test: '//test-i.hiworkglobal.com',
  demos: '//i-hiworkglobal.hiwork.com',
  qa: '//qa-i.hiworkglobal.com',
  prod: '//i.hiworkglobal.com',
};

export const getIUri = () => {
  const env = getEnv();
  return iuris[env];
};

const ehiworkuris = {
  test: '//test-e.hiwork.com',
  demos: '//demos-e.hiwork.com',
  qa: '//qa-e.hiwork.com',
  prod: '//e.hiwork.com',
};
export const getEhiworkUri = () => {
  const env = getEnv();
  return ehiworkuris[env];
};
