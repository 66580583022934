<template>
  <el-dropdown trigger="click">
    <div class="avatar">
      {{
        globalStore.userinfo?.user_name
          ? globalStore.userinfo?.user_name?.slice(0, 1)
          : 'A'
      }}
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item class="pointer-events-none">
          <div>你好，{{ globalStore.userinfo.user_name }}</div>
        </el-dropdown-item>
        <el-dropdown-item divided @click="goPersonalData">
          {{ _t('header.personalSetting') }}
        </el-dropdown-item>
        <el-dropdown-item @click="hLogOut">
          {{ _t('header.logout') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script setup lang="ts">
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import { useGlobalStore } from '@/store/module/global';
import { hLogOut } from '@/utils/logout';
const _t = inject<any>('_t');

const router = useRouter();
const globalStore = useGlobalStore();

const goPersonalData = () => {
  router.push('/setup/personalInfo');
};
</script>
<style scoped lang="less">
.avatar {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--el-color-primary);
  color: var(--hiwork-global-color9);

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
