import service from '@/utils/axios';
import { getApiUri } from '@/utils/envv';
const api = getApiUri() + '/api';
import qs from 'qs';

//  合作主体信息
export const invoicecooperationsubejctinfo = () => {
  return service.post(`${api}/invoice/cooperation/subject/info`);
};
//  发票列表查询
export const invoicelist = (params: object) => {
  return service.post(`${api}/invoice/list`, params);
};

//  开票状态枚举
export const invoicestatusenum = () => {
  return service.post(`${api}/invoice/status/enum`);
};
//  开票主体相关信息
export const invoicesubjectinfo = () => {
  return service.post(`${api}/invoice/subject/info`);
};

//  更新发票主体相关信息
export const invoicesubjectupdate = (params: object) => {
  return service.post(`${api}/invoice/subject/update`, params);
};
// 结算单发票下载
export const invoicesettlementfiledownload = (params: any) => {
  return service.get(
    `${api}/invoice/settlement/file/download?${qs.stringify(params)}`,
  );
};
