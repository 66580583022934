import { useGlobalStore } from '@/store/module/global';

// 是否企业管理员
export const isMaster = () => {
  const globalStore = useGlobalStore();
  const roles = globalStore.userinfo.manage_roles || [];
  return roles.includes('default:master');
};

export const isInvoiceManagement = () => {
  const globalStore = useGlobalStore();
  return globalStore.userinfo.invoiceManagement;
};
