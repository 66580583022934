import service from '@/utils/axios';
import { getApiUri } from '@/utils/envv';
const api = getApiUri() + '/api';

//  添加人员并邀请签署
export const employeeaddAndSign = (params: object) => {
  return service.post(`${api}/employee/addAndSign`, params);
};
//  税收国家/地区
export const employeetaxcountry = (params: object) => {
  return service.post(`${api}/location/tax/country`, params);
};
//  币种
export const getCurrency = (params: object) => {
  return service.post(`${api}/location/tax/currency`, params);
};
//  人员列表
export const employeeList = (params: object) => {
  return service.post(`${api}/employee/list`, params);
};
// 人员删除
export const employeeDelete = (params: object) => {
  return service.post(`${api}/employee/delete`, params);
};
// 状态
export const employeeStatus = (params: object) => {
  return service.post(`${api}/employee/status/enum`, params);
};
// 模版下载
export const downTemplate = (params: object) => {
  return service.get(`${api}/employee/download`, { params });
};
// 导入-第一步保存
export const employeeCheck = (params: object) => {
  return service.post(`${api}/employee/check`, params);
};
// 批量导入 - 第二步
export const employeeQuery = (params: object) => {
  return service.post(`${api}/employee/excel/query`, params);
};
// 删除表格单条数据
export const employeeExcelDelete = (params: object) => {
  return service.post(`${api}/employee/excel/delete`, params);
};
// 第三步-立即邀请
export const employeeInvite = (params: object) => {
  return service.post(`${api}/employee/invite`, params);
};
// 导出明细
export const employeeexceldownload = (params: object) => {
  return service.post(`${api}/employee/excel/download`, params);
};
// 人员详情
export const employeedetail = (params: object) => {
  return service.post(`${api}/employee/detail`, params);
};
// 编辑人员
export const employeeedit = (params: object) => {
  return service.post(`${api}/employee/edit`, params);
};
// 导出人员列表
export const employeeexportlist = (params: object) => {
  return service.post(`${api}/employee/export/list`, params);
};
// 人员催办
export const employeeexpediteProgress = (params: object) => {
  return service.post(`${api}/employee/expediteProgress`, params);
};
