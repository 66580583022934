// import { Avatar, HomeFilled, List } from '@element-plus/icons-vue';
// import Dashboard from '@/assets/vue.svg?component';
import i18n from './languages/index';
import { computed } from 'vue';
import { isMaster, isInvoiceManagement } from './utils/roles';
const { t } = i18n.global;

const menuList = computed(() => [
  {
    path: '/home',
    name: t('menu.home'),
    iconfont: 'icon-menu-home',
  },
  {
    path: '/personnel/list',
    name: t('menu.personnelmanagement'),
    iconfont: 'icon-menu-personnel',
  },
  {
    path: '/settlement',
    name: t('menu.settlementmanagement'),
    iconfont: 'icon-menu-settlement',
    children: [
      {
        path: '/settlement/batch',
        name: t('menu.batchsettlement'),
      },
      {
        path: '/settlement/detail',
        name: t('menu.detailsettlement'),
      },
    ],
  },
  {
    path: '/invoicesManagement',
    name: t('menu.invoicesManagement'),
    iconfont: 'icon-menu-invoices',
    isHidden: !isInvoiceManagement(),
  },
  {
    path: '/setup',
    name: t('menu.setup'),
    iconfont: 'icon-menu-setting',
    children: [
      {
        path: '/setup/information/form',
        name: t('menu.information'),
        isHidden: !isMaster(),
      },
      {
        path: '/setup/personalInfo',
        name: t('menu.personalInfo'),
      },
      {
        path: '/setup/account/list',
        name: t('menu.accountManagement'),
        isHidden: !isMaster(),
      },
    ],
  },
]);
export default menuList;
