<template>
  <div class="sidebar-container">
    <div v-if="showLogo" class="logo_box">
      <div v-if="!collapsed" class="logo">
        <img src="/src/assets/images/header.png" class="w-87px h-23px" alt="" />
      </div>
      <div v-if="collapsed" class="logo_collapsed">
        <img src="/src/assets/images/logo.png" class="w-32px h-32px" alt="" />
      </div>
      <div v-if="collapsed" class="logo_line"></div>
      <div v-if="!collapsed" class="busin">
        <Business />
      </div>
    </div>
    <ElScrollbar>
      <ElMenu
        :router="true"
        :default-active="$route.path"
        :collapse-transition="false"
        :collapse="collapsed"
        :class="collapsed ? 'w-88px!' : 'w-260px'"
      >
        <template v-for="item in menuList" :key="item.path">
          <template v-if="item.children && item.children.length > 0">
            <ElSubMenu :index="item.path">
              <template #title>
                <ElIcon v-if="item.icon">
                  <component :is="item.icon" />
                </ElIcon>
                <div
                  v-if="item.iconfont"
                  :class="[
                    'iconfont',
                    $route.path === item.path
                      ? item.iconfont + '-active'
                      : item.iconfont,
                  ]"
                ></div>
                <span class="w-8px"></span>
                <span>{{ item.name }}</span>
              </template>
              <template
                v-for="childItem in item.children"
                :key="childItem.path"
              >
                <ElMenuItem v-if="!childItem?.isHidden" :index="childItem.path">
                  <div class="w-12px"></div>
                  {{ childItem.name }}
                </ElMenuItem>
              </template>
            </ElSubMenu>
          </template>
          <template v-else>
            <ElMenuItem v-if="!item?.isHidden" :index="item.path">
              <ElIcon v-if="item.icon">
                <component :is="item.icon" />
              </ElIcon>
              <div
                v-if="item.iconfont"
                :class="[
                  'iconfont',
                  $route.path === item.path
                    ? item.iconfont + '-active'
                    : item.iconfont,
                ]"
              ></div>
              <span class="w-8px"></span>
              <span class="ttitle">{{ item.name }}</span>
            </ElMenuItem>
          </template>
        </template>
      </ElMenu>
    </ElScrollbar>
    <div
      :class="['collapse-btn', collapsed && 'collapsed']"
      @click="handleToggleCollapse"
    >
      <ElIcon>
        <template v-if="!collapsed">
          <IEpFold />
        </template>
        <template v-else>
          <IEpExpand />
        </template>
      </ElIcon>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useCollapsed from './useCollapsed';
import Business from './components/business.vue';
import type { MenuItem } from './type';

type SidebarProps = {
  menuList: MenuItem[];
  showLogo?: boolean;
};

withDefaults(defineProps<SidebarProps>(), {
  menuList: () => [],
  showLogo: true,
});

const { collapsed, handleToggleCollapse } = useCollapsed();
</script>

<style lang="less" scoped>
.sidebar-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  .logo_box {
    max-width: 260px;
    box-sizing: border-box;
  }

  .logo {
    padding: 0px 24px;
    position: relative;
    height: 74px;
    display: flex;
    align-items: center;
  }
  .logo_collapsed {
    padding-top: 22px;
    text-align: center;
    height: 32px;
  }
  .busin {
    padding: 0px 24px;
  }

  .logo_line {
    position: relative;
    margin: 20px 20px 0 20px;
    height: 1px;
    background-color: var(--el-menu-border-color);
  }

  .collapse-btn {
    align-items: center;
    border-radius: 2px;
    bottom: 12px;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    position: absolute;
    right: 12px;
    width: 24px;
  }

  .collapse-btn.collapsed {
    left: 50%;
    transform: translateX(-50%);
  }

  .el-menu {
    padding: 30px 24px;
    border-right: none;
    height: 100%;
    box-sizing: border-box;
  }

  .el-menu--collapse {
    padding: 20px;
  }

  .el-menu-item {
    margin-bottom: 4px;
  }

  .el-menu-item:hover {
    border-radius: 10px;
  }

  .el-menu-item.is-active {
    border-radius: 10px;
  }
}

:deep(.el-sub-menu__title) {
  margin-bottom: 4px;
}

:deep(.el-sub-menu__title:hover) {
  background-color: var(--hiwork-global-bg-color);
  border-radius: 10px;
}

:deep(.el-menu--collapse .el-menu-item) {
  justify-content: center;
  padding: 0;
}

:deep(.el-menu--collapse .el-sub-menu__title) {
  justify-content: center;
  padding: 0;
}
</style>
