import { defineStore } from 'pinia';
import { companybindlist } from '@/api';

export const useGlobalStore = defineStore('global', {
  state: () => ({
    ttl: '',
    layout: 'twoCloums',
    language: 'zh',
    maximize: false,
    userinfo: {} as any,
    companys: [] as any[],
    flushToken: '',
    globalLoading: false,
  }),
  actions: {
    setTtl(ttl: string) {
      this.ttl = ttl;
    },
    setLayout(layout: string) {
      this.layout = layout;
    },
    setLanguage(language: string) {
      this.language = language;
    },
    setMaximize(maximize: boolean) {
      this.maximize = maximize;
    },
    setUserinfo(userinfo: any) {
      this.userinfo = userinfo;
    },
    setCompanys() {
      companybindlist().then((res: any) => {
        if (res.data.code === 22000) {
          this.companys = res.data.result.list;
          this.flushToken = res.data.result.flushToken;
        }
      });
    },
    setGlobalLoading(loading: boolean) {
      this.globalLoading = loading;
    },
  },
});
