<template>
  <div
    v-loading="globalStore.globalLoading"
    class="layout_two_cloums"
    :element-loading-text="_t('layout.loadingmsg')"
  >
    <div class="menu">
      <Menu :menu-list="menuList" :show-logo="true" />
    </div>
    <div class="main_box">
      <div class="main">
        <div class="main_header">
          <ToolBarLeft :show-logo="false" />
          <ToolBarRight />
        </div>
        <div class="main_content">
          <Main ref="mainRef" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ToolBarLeft from '../components/header/toolBarLeft.vue';
import ToolBarRight from '../components/header/toolBarRight.vue';
import Menu from '../components/menu/index.vue';
import Main from '../components/main/index.vue';
import menuList from '@/menu';
import { ref, inject } from 'vue';
import { useGlobalStore } from '@/store/module/global';

const globalStore = useGlobalStore();

const _t = inject('_t');

const mainRef = ref();
provide('mainRef', mainRef);
</script>

<style lang="less" scoped>
.layout_two_cloums {
  min-width: 1280px;
  height: 100%;
  display: flex;
  background-color: var(--hiwork-global-bg-color);

  .menu {
    width: auto;
    background-color: var(--el-header-bg-color);
    border-radius: 0 20px 0 0;
  }
  .main_box {
    flex: 1;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .main {
    width: 100%;
    height: 100%;
    overflow: auto;

    .main_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // height: 48px;
      padding: 24px 28px;
    }

    .main_content {
      width: 100%;
      height: auto;
    }
  }
}
</style>
