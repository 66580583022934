<template>
  <div class="business">
    <el-select v-model="value" @change="hChange">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>
<script lang="ts" setup>
import { inject, nextTick, ref } from 'vue';
import { useKeepAliveStore } from '@/store/module/keepAlive';
import { useRoute } from 'vue-router';
// import { companybindlist, companychange } from '@/api'
// companybindlist({})
const route = useRoute();
const keepAliveStore = useKeepAliveStore();
const options = [
  {
    value: 'Option',
    label: 'Option1',
  },
  {
    value: 'Option2',
    label: 'Option2',
  },
  {
    value: 'Option3',
    label: 'Option3',
  },
  {
    value: 'Option4',
    label: 'Option4',
  },
  {
    value: 'Option5',
    label: 'Option5',
  },
];
const value = ref('Option');
const refreshCurrentPage: Function = inject('refresh') as Function;
const refresh = () => {
  setTimeout(() => {
    keepAliveStore.removeKeepAliveName(route.name as string);
    refreshCurrentPage(false);
    nextTick(() => {
      keepAliveStore.addKeepAliveName(route.name as string);
      refreshCurrentPage(true);
    });
  }, 0);
};
const hChange = () => {
  refresh();
};
</script>
<style lang="less" scoped>
.business {
  :deep(.el-select) {
    .el-input__wrapper {
      box-shadow: none !important;
      background-color: transparent;

      &.is-focus {
        box-shadow: none !important;
      }

      &:hover {
        box-shadow: none !important;
      }
    }
  }
}
</style>
