import service from '@/utils/axios';
import qs from 'qs';
import { getApiUri } from '@/utils/envv';
const api = getApiUri() + '/api';

// 个人信息
export const personbaseInfo = () => {
  return service.get(`${api}/person/baseInfo`);
};
// 验证邮箱
export const personcheckEmail = (params: any) => {
  return service.post(`${api}/person/checkEmail?${qs.stringify(params)}`);
};
// 加入的企业
export const personbindCompanyDetail = (params: any) => {
  return service.get(`${api}/person/bindCompanyDetail?${qs.stringify(params)}`);
};
// 校验手机验证码
export const personcheckPhoneCode = (params: any) => {
  return service.post(`${api}/person/checkPhoneCode?${qs.stringify(params)}`);
};
// 验证密码
export const personcheckPwd = (params: any) => {
  return service.post(`${api}/person/checkPwd?${qs.stringify(params)}`);
};
// 修改邮箱
export const personeditEmail = (params: any) => {
  return service.post(`${api}/person/editEmail?${qs.stringify(params)}`);
};
// 修改姓名
export const personeditName = (params: any) => {
  return service.post(`${api}/person/editName?${qs.stringify(params)}`);
};
// 修改登录密码
export const personpassword = (params: any) => {
  return service.post(`${api}/person/password?${qs.stringify(params)}`);
};
// 修改手机号
export const personphoneedit = (params: any) => {
  return service.post(`${api}/person/phone/edit?${qs.stringify(params)}`);
};
// 发送验证码到指定邮箱
export const personsendCodeToMail = (params: any) => {
  return service.post(`${api}/person/sendCodeToMail?${qs.stringify(params)}`);
};
// 发送手机验证码
export const personsendCodeToPhone = (params: any) => {
  return service.post(`${api}/person/sendCodeToPhone?${qs.stringify(params)}`);
};
// 获取语音验证码
export const personsendVoice = (params: any) => {
  return service.post(`${api}/person/sendVoice?${qs.stringify(params)}`);
};
// 设置商户密码
export const personsetPassword = (params: any) => {
  return service.post(`${api}/person/setPassword?${qs.stringify(params)}`);
};
// 获取手机验证码(设置新手机号时使用)
export const personupdatePhoneSendCode = (params: any) => {
  return service.post(
    `${api}/person/updatePhoneSendCode?${qs.stringify(params)}`,
  );
};
// 退出企业
export const personunbind = (params: any) => {
  return service.post(`${api}/person/unbind?${qs.stringify(params)}`);
};
