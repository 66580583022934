import { Action, ElMessageBox } from 'element-plus';
import i18n from '@/languages/index';
import router from '@/router';
export const handler = () => {
  const { t } = i18n.global;
  ElMessageBox.confirm(t('common.authFailedTip'), t('common.authFailedTitle'), {
    // if you want to disable its autofocus
    autofocus: false,
    showClose: false,
    showCancelButton: true,
    confirmButtonText: t('common.reauth'),
    cancelButtonText: t('common.cancel'),
    callback: (action: Action) => {
      if (action === 'confirm') {
        router.replace({ path: window.location.pathname });
      }
      if (action === 'cancel') {
        router.go(-1);
      }
    },
  });
};
