import { Directive } from 'vue';
import { useThrottleFn } from '@vueuse/core';
export const throttleClick: Directive = {
  mounted(el, binding) {
    let { arg, value } = binding;
    if (typeof value !== 'function') return;
    let delay: number = arg ? Number(arg) : 2000;

    const throttledFn = useThrottleFn(value, delay);
    el.addEventListener('click', throttledFn);
  },
};
