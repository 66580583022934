<script setup lang="ts">
import Layout from '@/layouts/index.vue';
import { getBrowserLang } from '@/utils';
//@ts-ignore
import { useI18n } from 'vue-i18n';
import { useGlobalStore } from './store/module/global';
import { computed, onMounted, provide } from 'vue';
import en from 'element-plus/es/locale/lang/en';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import VersionCheck from '@/components/versionCheck/index.vue';

const globalStore = useGlobalStore();
const i18n = useI18n();

const language = globalStore.language || getBrowserLang();
i18n.locale.value = language;
globalStore.setLanguage(language);

// element language
const locale = computed(() => {
  if (globalStore.language == 'zh') return zhCn;
  if (globalStore.language == 'en') return en;
  return getBrowserLang() == 'zh' ? zhCn : en;
});
provide('_t', i18n.t);
onMounted(() => {});
</script>

<template>
  <el-config-provider :locale="locale">
    <Layout />
    <VersionCheck />
  </el-config-provider>
</template>

<style scoped>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
