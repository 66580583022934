import axios from 'axios';
import { useStorage } from '@vueuse/core';
import router from '@/router';
import { useGlobalStore } from '@/store/module/global';
import { getBrowserLang } from '@/utils';
import { ElMessage } from 'element-plus';
import { getLoginUri } from '@/utils/envv';
import { handler as handler23010 } from './axios23010';

const companyId = useStorage('company_id', '');
const hisnotice = useStorage('hisnotice', '');
const udesk = useStorage('udesk', '');
const hiworkLogoutUrl = getLoginUri();
const passErrCodes = [24022];
const service = axios.create({
  timeout: 50000,
  headers: {
    token: localStorage.getItem('ttl'),
    'customer-code': companyId.value || '',
  },
});

service.interceptors.request.use(
  (config) => {
    const globalStore = useGlobalStore();
    config.headers['lang'] = globalStore.language || getBrowserLang();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    if (response.data.code === 22002) {
      localStorage.clear();
      companyId.value = null;
      hisnotice.value = null;
      udesk.value = null;
      let ttlurl =
        hiworkLogoutUrl +
        '?hiworkurl=' +
        window.location.protocol +
        '//' +
        window.location.host +
        '&reback=' +
        encodeURIComponent(window.location.href) +
        '&loginout=1';
      window.location.href = ttlurl;
    } else if (response.data.code == 22108) {
      // 没有权限
      router.push({ path: '/noAuth' });
    } else if (response.data.code == 22109 || response.data.code == 22107) {
      // 没有产品
      router.push({ path: '/nocompany' });
    } else if (response.data.code == 23001) {
      location.reload();
      // console.log('切换失败');
    } else if (passErrCodes.includes(response.data.code)) {
    } else if (response.data.code == 23010) {
      handler23010();
    } else if (response.data.code !== 22000) {
      ElMessage.error(response.data.message);
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      router.push({ path: '/login' });
    }
    return Promise.reject(error);
  },
);

export default service;
