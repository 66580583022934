<template>
  <div style="display: flex; align-items: center">
    <img
      v-if="retreat"
      src="@/assets/images/back.png"
      class="retreat"
      alt=""
      @click="$router.go(-1)"
    />
    <div class="route_name">{{ routeTitle ? _t(routeTitle + '') : '' }}</div>
  </div>
</template>
<script setup lang="ts">
import { computed, inject } from 'vue';
import { useRoute } from 'vue-router';

const _t = inject<any>('_t');
const route = useRoute();
const routeTitle = computed(() => {
  return route.meta.title;
});
const retreat = computed(() => {
  return route.meta.retreat;
});
</script>
<style lang="less" scoped>
.route_name {
  font-size: 18px;
  // font-family: PingFang SC;
  color: var(--hiwork-global-color);
  font-weight: 700;
}
.retreat {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
}
</style>
