<template>
  <!-- 去 国内大厅 -->
  <div class="to_home" @click="goHomeHiWork">
    {{ _t('header.toHomeHiWork') }}
    <div class="w-12px"></div>
    <div class="w-1px"></div>
  </div>
</template>

<script setup lang="ts">
import { getEhiworkUri } from '@/utils/envv';
import { inject } from 'vue';
const _t = inject<any>('_t');
const goHomeHiWork = () => {
  const url = getEhiworkUri();
  window.open(url, '_blank');
};
</script>
<style lang="less" scoped>
.to_home {
  font-size: 14px;
  color: var(--hiwork-global-color);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.w-1px {
  height: 12px;
  background-color: var(--hiwork-global-border-color7);
}
</style>
