export default {
  home: {
    welcome: 'Welcome',
    quickEntry: 'Quick Entry',
    pendingPaym: 'Pending Payment',
    collectionAccount: 'Collection account',
  },
  tabs: {
    refresh: 'Refresh',
    maximize: 'Maximize',
    closeCurrent: 'Close current',
    closeLeft: 'Close Left',
    closeRight: 'Close Right',
    closeOther: 'Close other',
    closeAll: 'Close All',
  },
  header: {
    componentSize: 'Component size',
    language: 'Language',
    theme: 'theme',
    layoutConfig: 'Layout config',
    primary: 'primary',
    darkMode: 'Dark Mode',
    greyMode: 'Grey mode',
    weakMode: 'Weak mode',
    fullScreen: 'Full Screen',
    exitFullScreen: 'Exit Full Screen',
    personalSetting: 'Personal settings',
    changePassword: 'Change Password',
    logout: 'Logout',
    helpCenter: 'Help Center',
  },
  menu: {
    home: 'Home',
    personnelmanagement: 'Personnel management',
    settlementmanagement: 'Settlement management',
    batchsettlement: 'Settlement batch',
    detailsettlement: 'Settlement detail',
    setup: '设置', // 词条
    accountManagement: '账号管理', // 词条
  },
  placeholder: {
    input: 'Enter',
    select: 'Please select',
  },
};
