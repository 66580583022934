<template>
  <el-dialog
    v-model="dvisible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    top="15vh"
    width="416px"
  >
    <div class="dialog_c">
      <img class="dialog_c_b" src="@/assets/images/version-check.png" alt="" />
      <div class="dialog_c_t">{{ _t('common.newVersion') }}</div>
      <div class="h-26px"></div>
      <div class="dialog_c_btn">
        <el-button type="primary" @click="hRefresh">
          {{ _t('common.refreshrightnow') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts" setup>
//@ts-ignore
// ui 验收1
import webVersionCheck from '@shebao/vite-plugin-version/web-version-check';
import { ref, inject } from 'vue';
const _t = inject<any>('_t');
const dvisible = ref(false);
window.location.hostname != 'localhost' &&
  webVersionCheck(
    {
      pollingInterval: 60 * 1000,
    },
    () => {
      dvisible.value = true;
    },
  );
const hRefresh = () => {
  location.reload();
};
</script>
<style lang="less" scoped>
.dialog_c {
  position: absolute;
  width: 416px;
  height: 160px;
  top: -25px;
  left: 0;
  z-index: 999;
  padding: 30px 32px 16px 36px;
  box-sizing: border-box;
  .dialog_c_b {
    width: 416px;
    position: absolute;
    top: -23px;
    left: 0;
    z-index: -1;
  }
  .dialog_c_t {
    width: 160px;
    height: 56px;
    font-size: 16px;
    color: var(--hiwork-global-color11);
    line-height: 28px;
    font-weight: 500;
  }
  .dialog_c_btn {
    display: flex;
    justify-content: flex-end;
    .el-button {
      height: 32px !important;
      padding: 5px 16px !important;
      --el-border-radius-base: 8px;
    }
  }
}
</style>
